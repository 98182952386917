<template>
  <div>
    <!------------ Start: FormHelper ------------>
    <FormHelper
      v-model="computedValue"
      :form="form"
      :config="formHelperConfig"
      @change="onChange"
    />
    <!------------ END: FormHelper ------------>
  </div>
</template>

<script>
import { base } from "@/components/Tools/FormHelper/Helper/mixins";

export default {
  components: {
    FormHelper: () => import("@/components/Tools/FormHelper/FormHelper")
  },
  mixins: [base],
  props: {
    groupIndex: {
      type: Number,
      default: 0
    },
    config: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {};
  },
  computed: {
    value: {
      get: function () {
        // Return inherited value
        return this.defaultValue;
      },
      set: function (value) {
        // Trigger input event with new value
        this.onInput(value);
      }
    },
    computedValue: {
      get: function () {
        // Get this group element by given group index
        return Object.assign({}, this.value[this.groupIndex]);
      },
      set: function (value) {
        // Update group
        this.value = {
          index: this.groupIndex,
          value: value
        };
      }
    },
    form: function () {
      return this.field.fields.map(f => {
        f.class = (f.class ?? "") + " py-1";
        return f;
      });
    },
    formHelperConfig: function () {
      const overwriteConfig = {
        title: "",
        groupIndex: this.groupIndex
      };
      // Get parent's config and overwrite it partially
      return Object.assign({}, this.options, overwriteConfig, this.config, {
        showLabels: this.showLabel
      });
    },
    showLabel: function () {
      const showLabel = this.options?.showLabels;

      if (this.field?.compactLayout) {
        return showLabel && this.groupIndex === 0;
      }

      return showLabel;
    }
  },
  mounted() {},
  methods: {
    onChange(payload) {
      this.$emit("change", payload);
    }
  }
};
</script>
