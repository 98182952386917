<script setup>
import { defineProps, onUnmounted, ref, watch } from "vue";

const props = defineProps({
  icon: {
    type: String,
    required: true
  },
  size: {
    type: String, // xs | sm | md | lg | xl
    default: "md"
  },
  color: {
    type: String, // default | info | success | warning | error | inverse | muted
    default: "default"
  }
});

const svg = ref();

const unwatch = watch(
  () => props.icon,
  async () => {
    const result = await import(
      "@/assets/icons/xentral/" + props.icon + ".svg"
    );
    svg.value = result?.default;
  },
  { immediate: true }
);

onUnmounted(() => unwatch());
</script>

<template>
  <inline-svg
    v-if="svg"
    :src="svg"
    class="xentral-icon"
    :class="['xentral-icon--' + size, 'xentral-icon--' + color]"
  ></inline-svg>
</template>

<style scoped lang="scss">
$icon-sizes: (
  "sm": 12px,
  "md": 20px,
  "lg": 32px,
  "xl": 46px
);

$icon-colors: (
  "success": $color-connect-success,
  "warning": $color-connect-warning,
  "info": $color-connect-info,
  "error": $color-connect-error,
  "default": $color-connect-text,
  "inverse": #fff,
  "muted": #b5b5c3
);

.xentral-icon {
  @each $type, $size in $icon-sizes {
    &--#{$type} {
      height: $size;
      width: $size;
    }
  }

  @each $type, $color in $icon-colors {
    &--#{$type} {
      color: $color;
    }
  }
}
</style>
