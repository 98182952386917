<template>
  <!------------ START: FieldWrapper ------------>
  <FieldWrapper :field="field">
    <div
      v-if="isMounted"
      :class="field.compactLayout ? 'compact-group' : 'group-wrapper'"
    >
      <!------------ START: Groups ------------>
      <div v-for="(group, i) in value" :key="i">
        <div
          :class="
            field.compactLayout ? 'compact' : 'pa-4 bg-light rounded group-item'
          "
        >
          <!------------ START: Group Element ------------>
          <!-- Set whole group as v-model, because value[i] wouldn't trigger update when changing -->
          <GroupElement
            v-model="computedValue"
            :config="additionalConfig"
            :field="field"
            :group-index="i"
            @change="payload => onGroupElementChange(i, payload)"
          />
          <!------------ END: Group Element ------------>
          <div
            class="d-flex align-self-bottom button-wrapper"
            :class="{
              'justify-content-end': field.deleteButtonType !== 'button'
            }"
          >
            <!------------ START: Move button ------------>
            <div
              v-if="value.length > 1 && field.showMoveArrows"
              :class="field.compactLayout ? 'compact-button' : 'my-0'"
              class="d-flex mr-2"
            >
              <button
                v-if="conditionUpButtonCondition(i, value.length)"
                v-b-popover.hover.top="$t('formHelper.moveConditionUp')"
                class="my-0 btn-hover-secondary text-muted mr-2"
              >
                <i class="fal fa-arrow-up" @click="moveCondition(i, 'up')"></i>
              </button>
              <button
                v-if="conditionDownButtonCondition(i, value.length)"
                v-b-popover.hover.top="$t('formHelper.moveConditionDown')"
                class="my-0 btn-hover-secondary text-muted"
              >
                <i
                  class="fal fa-arrow-down"
                  @click="moveCondition(i, 'down')"
                ></i>
              </button>
            </div>
            <!------------ END: Move button ------------>
            <!------------ START: Remove button ------------>
            <div
              v-if="showRemoveButton"
              :class="field.compactLayout ? 'compact-button' : 'my-0'"
            >
              <button
                v-if="field.deleteButtonType !== 'button'"
                v-b-popover.hover.top="removeGroupLabel"
                :class="
                  field.compactLayout
                    ? 'btn-icon btn-sm'
                    : 'my-0 btn-hover-secondary text-muted remove-button-wrapper'
                "
                @click="removeGroup(i)"
              >
                <i class="fal fa-trash" />
              </button>

              <button
                v-else
                class="btn group-delete-button d-flex align-center"
                @click="removeGroup(i)"
              >
                <XentralIcon v-if="field.showXentralIcon" icon="trash" />
                <i v-else class="fal fa-trash" /> {{ removeGroupLabel }}
              </button>
            </div>
          </div>
          <!------------ END: Move button ------------>
        </div>
      </div>
      <!------------ END: Groups ------------>
      <!------------ START: Add group button ------------>
      <div
        v-if="showAddButton"
        :class="field.compactLayout ? 'mt-4' : 'text-muted'"
        class="btn btn-link p-0 cursor-pointer text-hover-primary text-left"
        @click="addGroup"
      >
        <i class="fal fa-plus" /> {{ addGroupLabel }}
      </div>
      <!------------ END: Add group button ------------>
    </div>
  </FieldWrapper>
  <!------------ END: FieldWrapper ------------>
</template>

<script>
import {
  base,
  eagerValidation
} from "@/components/Tools/FormHelper/Helper/mixins";
import { fieldDefaults } from "@/components/Tools/FormHelper/Helper/constants";
import FieldWrapper from "@/components/Tools/FormHelper/Components/FieldWrapper";
import GroupElement from "@/components/Tools/FormHelper/Components/GroupElement";
import _ from "lodash";
import { getDataOptions } from "@/components/Tools/FormHelper/Helper/functions";
import XentralIcon from "@/components/Tools/Icons/XentralIcon.vue";

export default {
  components: {
    XentralIcon,
    GroupElement,
    FieldWrapper
  },
  mixins: [base, eagerValidation],
  inject: ["options"],
  provide: function () {
    return {
      parent: this.field
    };
  },
  props: {},
  data() {
    return {
      isMounted: false
    };
  },
  computed: {
    value: {
      get: function () {
        // Return inherited value
        return this.defaultValue;
      },
      set: function (value) {
        // Trigger input event with new value
        this.onInput(value);
      }
    },
    computedValue: {
      get: function () {
        return this.value;
      },
      set: function (payload) {
        // Payload only contains index and value of edited group
        let value = [...this.value];
        // Insert group into groups array
        value[payload.index] = payload.value;
        this.value = value;
      }
    },
    addGroupLabel: function () {
      return this.field.addGroupLabel
        ? this.getSnippet(this.field.addGroupLabel)
        : this.$t("formHelper.addGroup");
    },
    removeGroupLabel: function () {
      return this.field.removeGroupLabel
        ? this.getSnippet(this.field.removeGroupLabel)
        : this.$t("formHelper.removeGroup");
    },
    showAddButton: function () {
      if (!this.field.validations?.maxLength) {
        return true;
      }
      return this.value.length < this.field.validations.maxLength;
    },
    showRemoveButton: function () {
      if (!this.field.validations?.minLength) {
        return true;
      }
      return this.value.length > this.field.validations.minLength;
    },
    additionalConfig: function () {
      return this.field.compactLayout
        ? { showLabels: false, labelStacked: true }
        : {};
    }
  },
  async created() {
    if (this.field?.loadFormatter) {
      await this.$emit("load-formatter", {
        type: this.field?.loadFormatter,
        field: this.field,
        value: this.computedValue
      });
    }

    let optionsToFetch = [];

    for (const entry of this.field.fields) {
      const values = this.computedValue.map(val => val[entry.name]);
      const uniqueValues = _.uniq(values);
      let payload = { field: entry };

      if (uniqueValues?.length) {
        payload["values"] = uniqueValues;
      }

      optionsToFetch.push(payload);
    }

    for (const optionToFetch of optionsToFetch) {
      this.loadDataOptions(optionToFetch);
    }
  },
  mounted() {
    this.isMounted = true;
  },
  methods: {
    loadDataOptions(params) {
      let field = params.field;
      const {
        optionsClass,
        optionsMethod,
        optionsConfig: { onOpen = false } = {}
      } = field;

      if (!optionsClass || (onOpen && !params?.values)) return;
      field.loading = true;

      const payload = {
        optionsClass: optionsClass,
        optionsMethod: optionsMethod,
        value: params.values
      };

      getDataOptions(field, payload);
    },
    conditionUpButtonCondition: function (i, valueLength) {
      return i === valueLength - 1 || i !== 0;
    },
    conditionDownButtonCondition: function (i, valueLength) {
      return i === 0 || i !== valueLength - 1;
    },
    addGroup() {
      const group = {};
      // Set name with default value,
      // if name already exists set in null
      this.field.fields.forEach(field => {
        group[field.name] = Object.hasOwn(group, field.name)
          ? null
          : fieldDefaults[field.type];
      });
      // Add new group to values
      let value = [...(this.value ?? []), group];
      this.onChange(value, String(value.length - 1));
      this.value = value;
    },
    removeGroup(index) {
      let groups = [...this.value];
      // Remove group by index
      let value = groups.splice(index, 1)[0];
      this.onChange(value, String(index), true);
      this.value = groups;
    },
    moveCondition(index, direction) {
      const newIndex = direction === "up" ? index - 1 : index + 1;
      const newArray = [...this.value];

      [newArray[index], newArray[newIndex]] = [
        newArray[newIndex],
        newArray[index]
      ];

      this.value = newArray;
      this.onChange(newArray, String(index), false, String(newIndex));
    },
    onChange(value, path = "", remove = false, newIndex = null) {
      // Emit change event
      const changeEventPayload = {
        name: this.field.name,
        value: value,
        valuePath: path,
        remove: remove,
        move: !!(path && newIndex),
        newIndex: newIndex,
        old: path ? _.get(this.value, path) : _.cloneDeep(this.value)
      };
      this.$emit("change", changeEventPayload);
      // Execute onChange function if set
      if (typeof this.field.onChange === "function") {
        this.field.onChange();
      }
    },
    onGroupElementChange(index, payload) {
      payload.valuePath = `${index}.${payload.name}`;
      payload.name = this.field.name;
      this.$emit("change", payload);
    }
  }
};
</script>

<style lang="scss" scoped>
.remove-button-wrapper {
  color: $color-connect-text;

  i {
    color: $color-connect-text;
    transition: all 0.2s;
  }

  &:hover {
    i {
      color: $color-connect-primary;
    }
  }
}

.group-delete-button {
  gap: 10px;
  color: #f04438;
  transition: background-color 0.2s ease 0s;

  :deep(svg) {
    color: #f04438;
  }

  &:hover {
    background-color: #fee4e2;
  }
}

.compact-group {
  padding: 0 1rem 1rem;
  background-color: $color-connect-background;
  border-radius: 6px;

  .btn-link {
    color: $color-connect-text;

    i {
      color: $color-connect-text;
    }
  }
}

.compact {
  padding: 0;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto;
  gap: 20px;

  :deep(.form-helper) {
    > .row {
      display: grid;
      grid-template-columns: 50% 50%;
      gap: 10px;
      margin-bottom: 0;
      margin-top: 0;
    }

    .form-field {
      > div {
        margin-bottom: 0 !important; //We need to override the default margin on the first div after form-field
      }
    }

    .form-control {
      &.v-text-field {
        margin-top: 0;
      }
    }
  }

  .btn {
    &-icon {
      i {
        color: $color-connect-text;
      }

      &:hover {
        i {
          color: $color-connect-primary;
        }
      }
    }
  }
}

.compact-button {
  align-self: end;
  margin-bottom: 10px;
}

.group-wrapper,
.group-item {
  display: grid;
  gap: 16px;
  grid-auto-rows: auto;
  grid-auto-flow: row;
}
</style>
